import "./post.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";

export default function Post() {
	const [postData, setPostData] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "post"] | order(_updatedAt desc)[4..7]{
			title,
			slug,
			_updatedAt,
			mainImage{
				asset->{
					_id,
					url
				},
				alt
			},
			body,
			comments
		}`
			)
			.then((data) => setPostData(data))
			.catch(console.error);
	}, []);

	return (
		<>
			{postData &&
				postData?.map(({ mainImage, _updatedAt, slug, title, body }, index) => (
					<div class="card mb-3 post" key={index}>
						<div className="row no-gutters">
							<div className="col md-4 postImgCont">
								<img
									src={mainImage.asset.url}
									className="card-img postImg"
									alt={mainImage.alt}
								/>
							</div>
							<div className="col-md-8 postInfo">
								<div className="card-header postMeta">
									<span className="postDate">
										{new Date(_updatedAt)
											.toLocaleDateString()
											.replaceAll("/", ".")}
									</span>
									<span className="postComment">
										<FontAwesomeIcon
											className="postCommentIcon"
											icon={faComments}
										/>
										{/* 15 */}
									</span>
								</div>
								<div class="card-body">
									<Link
										className="link"
										to={"/post/" + slug.current}
										key={slug.current}
									>
										<div>
											<p class="card-text postTitle">{title}</p>
											<BlockContent
												className="card-text postDesc"
												blocks={body}
												projectId="24m9tu5n"
												dataset="production"
											/>
											<span className="postMore">
												<button className="postReadMoreBtn">read more</button>
											</span>
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				))}
			<button className="postLoadMoreBtn">
				<Link to="/allPosts" className="link">
					load more posts
				</Link>
			</button>
		</>
	);
}
