import "./allPost.css";
import sanityClient from "../../client";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import BlockContent from "@sanity/block-content-to-react";

export default function AllPost() {
	const [allPosts, setAllPosts] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "post"] | order(_updatedAt desc){
			title,
			slug,
			_updatedAt,
			mainImage{
				asset->{
					_id,
					url
				},
				alt
			},
			body,
			comments
		}`
			)
			.then((data) => setAllPosts(data))
			.catch(console.error);
	}, []);

	return (
		<div className="container">
			<div className="allPostsContent">
				{allPosts &&
					allPosts?.map(
						({ mainImage, _updatedAt, slug, title, body }, index) => (
							<div class="card mb-3 allPosts" key={index}>
								<div className="row no-gutters">
									<div className="col md-4 allPostsImgCont">
										<img
											loading="lazy"
											src={mainImage.asset.url}
											className="card-img allPostsImg"
											alt={mainImage.alt}
										/>
									</div>
									<div className="col-md-8 allPostsInfo">
										<div className="card-header allPostsMeta">
											<span className="allPostsDate">
												{new Date(_updatedAt)
													.toLocaleDateString()
													.replaceAll("/", ".")}
											</span>
											<span className="allPostsComment">
												<FontAwesomeIcon
													className="allPostsCommentIcon"
													icon={faComments}
												/>
												{/* 15 */}
											</span>
										</div>
										<div className="card-body">
											<Link
												className="link"
												to={"/post/" + slug.current}
												key={slug.current}
											>
												<div>
													<p class="card-text allPostsTitle">{title}</p>
													<BlockContent
														className="card-text allPostsDesc"
														blocks={body}
														projectId="24m9tu5n"
														dataset="production"
													/>
													<span className="allPostsMore">
														<button className="allPostsReadMoreBtn">
															read more
														</button>
													</span>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
						)
					)}
			</div>
		</div>
	);
}
