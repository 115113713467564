import "./latestPost.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";

export default function LatestPost() {
	const [latestPostData, setLatestPostData] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "post"] | order(_updatedAt desc){
					title,
					slug,
					_updatedAt,
					mainImage{
						asset->{
							_id,
							url
						},
						alt
					},
					body,
					comments
				}`
			)
			.then((data) => setLatestPostData(data[0]))
			.catch(console.error);
	}, []);

	return (
		<div className="latestPost">
			{latestPostData && (
				<div>
					<div className="lPostImg">
						<img
							loading="lazy"
							src={latestPostData.mainImage.asset.url}
							alt={latestPostData.mainImage.alt}
						/>
					</div>
					<div className="lPostMeta">
						<span className="lPostDate">
							{new Date(latestPostData._updatedAt)
								.toLocaleDateString()
								.replaceAll("/", ".")}
						</span>
						<FontAwesomeIcon
							className="lPostIcon"
							icon={faComments}
						></FontAwesomeIcon>
						<span className="lPostComment"></span>
					</div>
					<div className="lPostTitle">
						<Link
							className="link"
							to={"/post/" + latestPostData.slug.current}
							key={latestPostData.slug.current}
						>
							<h1 className="lPostHeading">{latestPostData.title}</h1>
						</Link>
					</div>
					<div className="lPostDesc">
						<BlockContent
							blocks={latestPostData.body}
							projectId="24m9tu5n"
							dataset="production"
						/>
					</div>
					<Link
						className="link"
						to={"/post/" + latestPostData.slug.current}
						key={latestPostData.slug.current}
					>
						<button className="lPostBtn">read the post</button>
					</Link>
				</div>
			)}
		</div>
	);
}
