import Single from "../../components/single/Single";
import Sidebar from "../../components/sidebar/Sidebar";
import "./singlePost.css";
import { useEffect } from "react";

export default function SinglePost() {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);
	return (
		<div className="d-flex container singlePost">
			<Single />
			<div className="sidebar">
				<Sidebar />
			</div>
		</div>
	);
}
