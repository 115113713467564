import { useParams } from "react-router-dom";
import { useEffect } from "react";
import MenuPost from "../../components/menuPost/MenuPost";
import "./menuPosts.css";

export default function MenuPosts() {
	const { menuSlug } = useParams();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className="container">
			<div className="menuItemHeader">
				<span className="menuItem">{menuSlug}</span>
			</div>
			<MenuPost />
		</div>
	);
}
