import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./searchBar.css";

export default function SearchBar() {
	const searchInputRef = useRef(null);
	const history = useHistory();
	const [showSearchBox, setShowSearchBox] = useState(false);

	const getSearchTerm = (e) => {
		e.preventDefault();

		const searchTerm = searchInputRef.current.value;

		if (!searchTerm) return;

		history.push(`/search?term=${searchTerm}`);

		searchInputRef.current.value = "";
		setShowSearchBox(false);
	};

	return (
		<div className="search-bar">
			<form>
				<FontAwesomeIcon
					className="navIcon"
					icon={faSearch}
					onClick={() => setShowSearchBox(!showSearchBox)}
				/>
				<input
					ref={searchInputRef}
					type="text"
					className={showSearchBox ? "navSearch" : "navSearchBox"}
					placeholder="search..."
				/>
				<button hidden type="submit" onClick={getSearchTerm}>
					search
				</button>
			</form>
		</div>
	);
}
