import "./randomPost.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import { useEffect, useState } from "react";

export default function Post() {
	const [randomPost, setRandomPost] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "post"] | order(_updatedAt desc)[1..3]{
			title,
			slug,
			categories[]->{
				title,
			},
			mainImage{
				asset->{
					_id,
					url
				},
				alt
			},
			comments
		}`
			)
			.then((data) => setRandomPost(data))
			.catch(console.error);
	}, []);

	return (
		randomPost &&
		randomPost?.map(({ mainImage, slug, title, categories }, index) => (
			<div
				className="card mb-3 randomPost"
				style={{ width: "30rem", maxWidth: "540px", height: "201px" }}
				key={index}
			>
				<div className="row no-gutters">
					<div className="col-md-4 randomPostImgCont">
						<img
							loading="lazy"
							src={mainImage.asset.url}
							className="card-img randomPostImg"
							alt={mainImage.alt}
						/>
					</div>
					<div className="col-md-8 randomPostInfo">
						<div className="card-header randomPostMeta">
							<Link className="link" to={"/posts/" + categories[0].title}>
								<span className="randomPostCat">{categories[0].title}</span>
							</Link>
							<span className="randomPostComment">
								<FontAwesomeIcon
									className="randomPostCommentIcon"
									icon={faComments}
								/>
								{/* 15 */}
							</span>
						</div>
						<div className="card-body">
							<Link
								className="link"
								to={"/post/" + slug.current}
								key={slug.current}
							>
								<div>
									<p class="card-text randomPostTitle">{title}</p>
									<span className="randomPostMore">
										read more
										<FontAwesomeIcon
											className="randomPostMoreIcon"
											icon={faLongArrowAltRight}
										/>
									</span>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		))
	);
}
