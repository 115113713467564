import SearchResults from "../../components/searchResults/SearchResults";
import { useLocation } from "react-router";
import "./search.css";

export default function Search() {
	const query = new URLSearchParams(useLocation().search);
	const queryTerm = query.get("term");

	return (
		<div className="container">
			<p className="searchResultsHeader">
				Search Results for: {queryTerm.toLowerCase()}
			</p>
			<SearchResults />
		</div>
	);
}
