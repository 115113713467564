import "./posts.css";
import Post from "../post/Post";

export default function Posts() {
	return (
		<div className="container posts">
			<Post />
		</div>
	);
}
