import { Link, NavLink } from "react-router-dom";
import "./header.css";
import sanityClient from "../../client";
import { useEffect, useState } from "react";

export default function Header() {
	const [menuData, setMenuData] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "menu"]{
			title,
			slug
		}`
			)
			.then((data) => setMenuData(data))
			.catch(console.error);
	}, []);

	return (
		<div className="header container">
			<div className="headerLogo">
				<Link className="link" to="/" exact>
					<span>Dreaming n Livin</span>
				</Link>
			</div>
			<label className="hamburger-toggler" htmlFor="toggle">
				&#9776; menu
			</label>
			<input type="checkbox" id="toggle" />
			<div className="headerLinks">
				{menuData &&
					menuData?.map(({ title, slug }, index) => (
						<NavLink
							key={index}
							className="headerLink"
							to={"/menu-item-posts/" + slug.current}
						>
							{title}
						</NavLink>
					))}
			</div>
		</div>
	);
}
