import "./footer.css";

export default function Footer() {
	return (
		<div className="footer">
			<div className="container footerSection">
				<div className="footerLinks">
					<span>dreamin n livin</span>
					<a rel="noreferrer" href="#">
						style
					</a>
					<a rel="noreferrer" href="#">
						books
					</a>
					<a rel="noreferrer" href="#">
						beauty
					</a>
					<a rel="noreferrer" href="#">
						wellness
					</a>
					<a rel="noreferrer" href="#">
						travel
					</a>
				</div>
				<div className="footerLinks">
					<span>social</span>
					<a
						rel="noreferrer"
						href="https://www.instagram.com/dreamingandlivin/"
						target="_blank"
					>
						instagram
					</a>
					<a rel="noreferrer" href="https://www.facebook.com" target="_blank">
						facebook
					</a>
					<a rel="noreferrer" href="https://www.twitter.com" target="_blank">
						twitter
					</a>
					<a rel="noreferrer" href="https://www.pinterest.com" target="_blank">
						pinterest
					</a>
					<a rel="noreferrer" href="https://www.youtube.com" target="_blank">
						youtube
					</a>
				</div>
				<div className="footerLinks">
					<span>connect</span>
					<a
						rel="noreferrer"
						href="https://mumbimutonga.wordpress.com/"
						target="_blank"
					>
						dreamin n livin
					</a>
					<a rel="noreferrer" href="#">
						rss
					</a>
				</div>
				<div className="footerLinks">
					<span>legal</span>
					<a
						rel="noreferrer"
						href="https://www.termsfeed.com/live/b67d305a-3cf0-4ea4-88eb-ed6734f77f0c"
						target="_blank"
					>
						terms of use
					</a>
					<a
						rel="noreferrer"
						href="https://www.termsfeed.com/live/486d10cb-521b-4f10-a012-50cf8a801f40"
						target="_blank"
					>
						privacy policy
					</a>
					<p>&copy; 2021</p>
					<p>all rights reserved</p>
				</div>

				<div className="footerLinks">
					<span>Contact Us</span>
					<a
						rel="noreferrer"
						href="https://us5.list-manage.com/contact-form?u=9a38a3705b1241fe12db8dda0&form_id=bc8558278f0dcef072d7c0b38d74f3b7"
						target="_blank"
					>
						Contact Us
					</a>
					<a rel="noreferrer" href="http://eepurl.com/hGVjwH" target="_blank">
						subscribe
					</a>
					<a
						rel="noreferrer"
						href="https://gmail.us5.list-manage.com/unsubscribe?u=9a38a3705b1241fe12db8dda0&id=68d63fac6c"
						target="_blank"
					>
						unsubscribe
					</a>
				</div>
			</div>

			<div className="container footerCredits">
				<span className="footerDesign">designed with ❤️ by c hash</span>
				<span className="footerCode">code by DUBE👾</span>
			</div>
		</div>
	);
}
