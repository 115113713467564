import "./categoryPost.css";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sanityClient from "../../client";
import { useEffect, useState } from "react";
import BlockContent from "@sanity/block-content-to-react";

export default function CategoryPost() {
	const [categoryPosts, setCategoryPosts] = useState(null);
	const { category } = useParams();

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "post"] | order(_updatedAt desc){
			title,
			slug,
			_updatedAt,
			mainImage{
				asset->{
					_id,
					url
				},
				alt
			},
			body,
			comments,
			categories[]->{
				title,
				slug
			}
		}`
			)
			.then((data) => setCategoryPosts(data))
			.catch(console.error);
	}, []);

	return (
		<div className="container">
			<div className="categoryPostContent">
				{categoryPosts &&
					categoryPosts
						?.filter(
							(categoryPost) =>
								categoryPost.categories[0].slug.current === category
						)
						?.map(({ title, slug, _updatedAt, mainImage, body }, index) => (
							<div class="card mb-3 categoryPost" key={index}>
								<div className="row no-gutters">
									<div className="col md-4 categoryPostImgCont">
										<img
											loading="lazy"
											src={mainImage.asset.url}
											className="card-img categoryPostImg"
											alt={mainImage.alt}
										/>
									</div>
									<div className="col-md-8 categoryPostInfo">
										<div className="card-header categoryPostMeta">
											<span className="categoryPostDate">
												{new Date(_updatedAt)
													.toLocaleDateString()
													.replaceAll("/", ".")}
											</span>
											<span className="categoryPostComment">
												<FontAwesomeIcon
													className="categoryPostCommentIcon"
													icon={faComments}
												/>
												{/* 15 */}
											</span>
										</div>
										<div className="card-body">
											<Link
												className="link"
												to={"/post/" + slug.current}
												key={slug.current}
											>
												<div>
													<p class="card-text categoryPostTitle">{title}</p>
													<BlockContent
														blocks={body}
														projectId="24m9tu5n"
														dataset="production"
														className="card-text categoryPostDesc"
													/>
													<span className="categoryPostMore">
														<button className="categoryPostReadMoreBtn">
															read more
														</button>
													</span>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
						))}
			</div>
		</div>
	);
}
