import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CategoryPost from "../../components/categoryPost/CategoryPost";
import "./postsByCategory.css";

export default function PostByCategory() {
	const { category } = useParams();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className="container">
			<div className="postCategoryHeader">
				<span className="postCategory">{category}</span>
			</div>
			<CategoryPost />
		</div>
	);
}
