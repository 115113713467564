import "./searchResults.css";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";

export default function SearchResults() {
	const [searchResults, setSearchResults] = useState(null);

	const query = new URLSearchParams(useLocation().search);
	const queryTerm = query.get("term");

	useEffect(() => {
		window.scroll(0, 0);
		sanityClient
			.fetch(
				`*[_type == "post"] | order(_updatedAt desc){
			title,
			slug,
			_updatedAt,
			mainImage{
				asset->{
					_id,
					url
				},
				alt
			},
			body,
			comments
		}`
			)
			.then((data) => setSearchResults(data))
			.catch(console.error);
	}, []);

	return (
		<div className="container">
			{searchResults &&
				searchResults
					?.filter((searchResult) => {
						if (
							searchResult.title
								.toLowerCase()
								.includes(queryTerm.toLowerCase()) ||
							searchResult.body
								?.map((block) =>
									block.children
										?.map((child) => child.text.toLowerCase())
										.join("")
								)
								.join("\n\n")
								.includes(queryTerm.toLowerCase())
						) {
							return searchResult;
						}
						return;
					})
					?.map(({ title, mainImage, _updatedAt, slug, body }) => (
						<div class="card mb-3 searchResults">
							<div className="row no-gutters">
								<div className="col md-4 searchResultsImgCont">
									<img
										loading="lazy"
										src={mainImage.asset.url}
										className="card-img searchResultsImg"
										alt={mainImage.alt}
									/>
								</div>
								<div className="col-md-8 searchResultsInfo">
									<div className="card-header searchResultsMeta">
										<span className="searchResultsDate">
											{new Date(_updatedAt)
												.toLocaleDateString()
												.replaceAll("/", ".")}
										</span>
										<span className="searchResultsComment">
											<FontAwesomeIcon
												className="searchResultsCommentIcon"
												icon={faComments}
											/>
											{/* 15 */}
										</span>
									</div>
									<div className="card-body">
										<Link className="link" to={"/post/" + slug.current}>
											<div>
												<p class="card-text searchResultsTitle">{title}</p>
												<BlockContent
													className="card-text searchResultsDesc"
													blocks={body}
													projectId="24m9tu5n"
													dataset="production"
												/>
												<span className="searchResultsMore">
													<button className="searchResultsReadMoreBtn">
														read more
													</button>
												</span>
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>
					))}
		</div>
	);
}
