import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Header from "./components/header/Header";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import SinglePost from "./pages/singlePost/SinglePost";
import PostsByCategory from "./pages/postsByCategory/PostsByCategory";
import AllPosts from "./pages/allPosts/AllPosts";
import Search from "./pages/search/Search";
import MenuPosts from "./pages/menuPosts/MenuPosts";

function App() {
	return (
		<div className="App">
			<Router>
				<Navbar />
				<Header />
				<Switch>
					<Route component={Home} path="/" exact />
					<Route component={About} path="/about" />
					<Route path="/search">
						<Search />
					</Route>
					<Route component={MenuPosts} path="/menu-item-posts/:menuSlug" />
					<Route component={SinglePost} path="/post/:slug" />
					<Route component={AllPosts} path="/allPosts" />
					<Route component={PostsByCategory} path="/posts/:category" />
				</Switch>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
