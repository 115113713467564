import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import "./category.css";

export default function Category() {
	const [categoryData, setCategoryData] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "category"]{
			title,
			slug
		}`
			)
			.then((data) => setCategoryData(data))
			.catch(console.error);
	}, []);

	return (
		<div class="card mt-5 mb-3 categories">
			<div class="card-body d-flex">
				<span className="categoryHeader">browse posts by topic : </span>
				{categoryData &&
					categoryData?.map(({ title, slug }, index) => (
						<div className="categoryLinks" key={index}>
							<Link className="link" to={"/posts/" + slug.current}>
								<span className="categoryLink">{title}</span>
							</Link>
						</div>
					))}
			</div>
		</div>
	);
}
