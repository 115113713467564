import { useEffect } from "react";
import AllPost from "../../components/allPost/AllPost";
import "./allPosts.css";

export default function AllPosts() {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);
	return (
		<div className="container">
			<div className="allPostsHeader">
				<span className="allPostsCategory">All Posts</span>
			</div>
			<AllPost />
		</div>
	);
}
