import {
	faFacebookF,
	faInstagram,
	faPinterest,
	faTwitter,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./sidebar.css";

export default function Sidebar() {
	return (
		<div className="sidebar container">
			<div className="sidebarSocial">
				<span className="socialTitle">Contact Me.</span>
				<div className="sidebarSocialIcons">
					<div className="row">
						<div className="col-md-6">
							<div className="sidebarSocialDets">
								<a
									rel="noreferrer"
									className="link"
									href="https://www.twitter.com"
									target="_blank"
								>
									<FontAwesomeIcon
										className="sidebarSocialIcon"
										icon={faTwitter}
									/>
								</a>
								<span>twitter</span>
							</div>
						</div>
						<div className="col-md-6">
							<div className="sidebarSocialDets">
								<a
									rel="noreferrer"
									className="link"
									href="https://www.facebook.com"
									target="_blank"
								>
									<FontAwesomeIcon
										className="sidebarSocialIcon"
										icon={faFacebookF}
									/>
								</a>
								<span>facebook</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="sidebarSocialDets">
								<a
									rel="noreferrer"
									className="link"
									href="https://www.pinterest.com"
									target="_blank"
								>
									<FontAwesomeIcon
										className="sidebarSocialIcon"
										icon={faPinterest}
									/>
								</a>
								<span>pinterest</span>
							</div>
						</div>
						<div className="col-md-6">
							<div className="sidebarSocialDets">
								<a
									rel="noreferrer"
									className="link"
									href="https://www.instagram.com/dreamingandlivin/"
									target="_blank"
								>
									<FontAwesomeIcon
										className="sidebarSocialIcon"
										icon={faInstagram}
									/>
								</a>
								<span>instagram</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="sidebarSocialDets">
								<a
									rel="noreferrer"
									className="link"
									href="https://www.youtube.com"
									target="_blank"
								>
									<FontAwesomeIcon
										className="sidebarSocialIcon"
										icon={faYoutube}
									/>
								</a>
								<span>youtube</span>
							</div>
						</div>
						<div className="col-md-6">
							<div className="sidebarSocialDets">
								<a
									rel="noreferrer"
									className="link"
									href="https://us5.list-manage.com/contact-form?u=9a38a3705b1241fe12db8dda0&form_id=bc8558278f0dcef072d7c0b38d74f3b7"
									target="_blank"
								>
									<FontAwesomeIcon
										className="sidebarSocialIcon"
										icon={faEnvelope}
									/>
								</a>
								<span>mail</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
