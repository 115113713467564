import Category from "../../components/category/Category";
import LatestPost from "../../components/latestPost/LatestPost";
import RandomPost from "../../components/randomPost/RandomPost";
import Posts from "../../components/posts/Posts";
import "./home.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect } from "react";

export default function Home() {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);
	return (
		<div className="container home">
			<div className="sectionOne">
				<LatestPost />
				<div className="randomPostSec">
					<RandomPost />
				</div>
			</div>
			<div className="sectionTwo">
				<Category />
				<div className="mainPostsSec">
					<Posts />
					<Sidebar />
				</div>
			</div>
		</div>
	);
}
