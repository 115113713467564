import {
	faFacebookF,
	faInstagram,
	faPinterest,
	faTwitter,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import SearchBar from "../searchBar/SearchBar";
import "./navbar.css";

export default function Navbar() {
	const [isScrolled, setIsScrolled] = useState(false);

	window.onscroll = () => {
		setIsScrolled(window.pageYOffset === 0 ? false : true);
		return () => (window.onscroll = null);
	};

	return (
		<>
			<div className={isScrolled ? "nav scrolled" : "nav"}>
				<nav className="topNav container">
					<div className="leftNav">
						<NavLink className="navLink" to="/about">
							About
						</NavLink>
						<a
							rel="noreferrer"
							className="navLink"
							href="https://mumbimutonga.wordpress.com/"
							target="_blank"
						>
							Dream Livin'
						</a>
						<a
							rel="noreferrer"
							className="navLink"
							href="http://eepurl.com/hGVjwH"
							target="_blank"
						>
							subscribe
						</a>
					</div>
					<div className="rightNav">
						<a
							rel="noreferrer"
							href="https://www.instagram.com/dreamingandlivin/"
							target="_blank"
						>
							<FontAwesomeIcon className="navIcon" icon={faInstagram} />
						</a>
						<a rel="noreferrer" href="https://www.facebook.com" target="_blank">
							<FontAwesomeIcon className="navIcon" icon={faFacebookF} />
						</a>
						<a rel="noreferrer" href="https://www.twitter.com" target="_blank">
							<FontAwesomeIcon className="navIcon" icon={faTwitter} />
						</a>
						<a
							rel="noreferrer"
							href="https://www.pinterest.com"
							target="_blank"
						>
							<FontAwesomeIcon className="navIcon" icon={faPinterest} />
						</a>
						<a rel="noreferrer" href="https://www.youtube.com" target="_blank">
							<FontAwesomeIcon className="navIcon" icon={faYoutube} />
						</a>
						<SearchBar />
					</div>
				</nav>
			</div>
		</>
	);
}
