import { faComments } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./single.css";
import sanityClient from "../../client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import Comment from "../comment/Comment";

export default function Single() {
	const [singlePost, setSinglePost] = useState(null);
	const { slug } = useParams();

	useEffect(() => {
		sanityClient
			.fetch(
				`*[slug.current == "${slug}"]{
			title,
			_id,
			slug,
			_updatedAt,
			mainImage{
				asset->{
					_id,
					url,
					alt
				}
			},
			body,
			"comments": *[_type == "comment" && post._ref == ^._id]{
				name,
				commentDescription,
				_createdAt
			}
		}`
			)
			.then((data) => setSinglePost(data[0]))
			.catch(console.error);
	}, [slug]);

	if (!singlePost) return <div>No post!! Loading...</div>;

	return (
		<>
			<div className="single">
				<div className="singleMeta">
					<span className="singleDate">
						{new Date(singlePost._updatedAt)
							.toLocaleDateString()
							.replaceAll("/", ".")}
					</span>
					<span className="singleComments">
						<FontAwesomeIcon className="singleCommentIcon" icon={faComments} />
						{/* 15 */}
					</span>
				</div>
				<div className="singleTitle">
					<span>{singlePost.title}</span>
				</div>
				<div className="singleImg">
					<img
						loading="lazy"
						src={singlePost.mainImage.asset.url}
						alt={singlePost.mainImage.alt}
					/>
				</div>
				<div className="singleDesc">
					<BlockContent
						blocks={singlePost.body}
						projectId="24m9tu5n"
						dataset="production"
					/>
				</div>

				{/* <Comment _id={singlePost._id} />

				<div className="comments">
					<div className="commentHeader">
						<span class="commentsHeader">comments</span>
					</div>
					<div class="card mb-3" style={{ minWidth: "18rem" }}>
						<div class="card-header">
							<span className="commentName">
								{singlePost.comments?.name} <span>says</span>
							</span>
							<span className="commentDate">
								{new Date(singlePost.comments?.createdAt)
									.toLocaleDateString()
									.replaceAll("/", ".")}
							</span>
						</div>
						<div class="card-body text-primary">
							<p class="card-text">{singlePost.comments?.commentDescription}</p>
						</div>
						<div className="card-footer commentsRelpyBtn">
							<button className="commentReplyBtn">reply</button>
						</div>
					</div>
				</div> */}
			</div>
		</>
	);
}
