import "./menuPost.css";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import { useEffect, useState } from "react";
import sanityClient from "../../client";

export default function MenuPost() {
	const [menuPost, setMenuPost] = useState(null);
	const { menuSlug } = useParams();

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "post"] | order(_updatedAt desc){
            title,
            slug,
            _updatedAt,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            body,
            menu[]->{
                title,
                slug
            }
        }`
			)
			.then((data) => setMenuPost(data))
			.catch(console.error);
	}, []);

	return (
		<div className="container">
			<div className="menuPostContent">
				{menuPost &&
					menuPost
						?.filter(
							(menuPostItem) => menuPostItem.menu[0].slug.current === menuSlug
						)
						?.map(({ title, slug, _updatedAt, mainImage, body }, index) => (
							<div class="card mb-3 menuPost" key={index}>
								<div className="row no-gutters">
									<div className="col md-4 menuPostImgCont">
										<img
											loading="lazy"
											src={mainImage.asset.url}
											className="card-img menuPostImg"
											alt={mainImage.alt}
										/>
									</div>
									<div className="col-md-8 menuPostInfo">
										<div className="card-header menuPostMeta">
											<span className="menuPostDate">
												{new Date(_updatedAt)
													.toLocaleDateString()
													.replaceAll("/", ".")}
											</span>
											<span className="menuPostComment">
												<FontAwesomeIcon
													className="menuPostCommentIcon"
													icon={faComments}
												/>
												{/* 15 */}
											</span>
										</div>
										<div className="card-body">
											<Link
												className="link"
												to={"/post/" + slug.current}
												key={slug.current}
											>
												<div>
													<p class="card-text menuPostTitle">{title}</p>
													<BlockContent
														blocks={body}
														projectId="24m9tu5n"
														dataset="production"
														className="card-text menuPostDesc"
													/>
													<span className="menuPostMore">
														<button className="menuPostReadMoreBtn">
															read more
														</button>
													</span>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
						))}
			</div>
		</div>
	);
}
